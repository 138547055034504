#main-sponsors {
  background-color: #fafafa;
  p {
    margin-bottom: 25px;
    padding-top: 40px;
    font-weight: 800;
  }
  ul {
    padding-bottom: 40px;
  }
  li {
    display: inline-block;
    max-width: 120px;
    max-height: 120px;
    vertical-align: middle;
    padding: 0 20px;
  }
}