.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 20px;
}
.col {
  width: 25%;
  float: left;
  &.col-2 {
    width: 50%;
  }
  &.col-3 {
    width: 75%;
  }
  &.col-alone {
    margin: 0 auto;
    float: none;
  }
}
.grid {
  overflow: hidden;
  padding: 20px 0 40px;
  .grid-item {
    &.event {
      &:hover {
        .event-inner {
          top: -18px;
          box-shadow: 0 22px 30px rgba(0,0,0,.3);
        }
      }
      box-sizing: border-box;
      padding: 20px;
      &.event-suggest {
        .event-inner {
          background: rgba(0,0,0,.3);
          color: $color1;
          .icon {
            padding: 20px;
            font-size: 50px;
            font-weight: bold;
            background: rgba(0,0,0,.5);
            display: inline-block;
            padding: 20px 30px;
            border-radius: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .event-name {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
          }
        }
      }
      .event-inner {
        position: relative;

        top: 0;
        min-height: 380px;
        background: $color1;
        border-radius: 10px;
        transition: all .3s ease;
        box-shadow: 0 4px 10px rgba(0,0,0,.5);
        text-align: center;
        font-weight: 800;
        padding-bottom: 10px;
        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          right: 1px;

          border: 1px solid rgba(255,255,255, .3);
          bottom: 1px;
          border-radius: 10px;
        }
      }
      .date {
        background: rgba(0,0,0,.5);
        display: inline-block;
        padding: 20px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          color: $color1;
          display: block;
          &.day {
            font-size: em(36);
          }
        }
      }
      .link-overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .thumb {
        height: 200px;
        position: relative;
        border-radius: 10px 10px 0 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .event-description {
        padding: 10px;
      }
      .event-tag {
        color: $color2;
        font-size: em(14);
        display: inline-block;
        margin: 10px 0;
        position: relative;
        z-index: 2;
      }
    }
  }
}