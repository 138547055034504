.left {
  float: left;
}
.right {
  float: right;
}
.clear {
  clear: both;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-justify {
  text-align: justify;
}
.no-desktop {
  display: none;
}
.hidden {
  display: none;
}
.block-text {
  padding: 30px 0 70px;
  line-height: 1.4;
  color: $color0;
  h1 {
    font-size: em(24);
    margin: 20px 0;
    font-weight: bold;
  }
  ol,
  ul {
    list-style-type: disc;
    padding-left: 30px;
    font-size: em(16);
    margin: 20px auto;
    li {
      a {
        color: $color2;
        font-weight: bold;
        text-transform: none;
      }
    }
    li + li {
      margin-top: 20px;
    }
  }
  ol {
    list-style-type: decimal;
  }
  p {
    font-size: 16px;
    em {
      font-style: italic;
    }
    strong {
      font-weight: bold;
    }
    a {
      color: $color2;
      font-weight: bold;
    }
  }
}
.section-white {
  background: $color1;
}
.section-title {
  font-weight: 800;
  font-size: em(24);
  padding: 20px 0;
  &.section-title-centered {
    text-align: center;
    opacity: .3;
    position: relative;
    top: -30px;
    span {
      display: block;
      float: left;
      &.center {
        position: absolute;
        top: 19px;
        left: 50%;
        float: none;
        transform: translateX(-50%);
      }
      &.before {
        text-align: left;
      }
      &.after {
        float: right;
        text-align: right;
      }
    }
  }
}
.form-custom {
  input[type=text],
  input[type=email],
  input[type=number],
  textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 6px;
    background: #f5f5f5;
    box-shadow: inset 0 0 10px rgba(0,0,0,.1);
    border-radius: 5px;
    border: 1px solid #ccc;
  }
}
.form-success {
  text-align: center;
  background: #a8f593;
  padding: 20px;
  margin-top: 50px;
  border-radius: 20px;
}