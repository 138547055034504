@font-face {
	font-family:'Monosten';
	src: url('../fonts/Monosten-E_gdi.eot');
	src: url('../fonts/Monosten-E_gdi.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monosten-E_gdi.woff') format('woff'),
		url('../fonts/Monosten-E_gdi.ttf') format('truetype'),
		url('../fonts/Monosten-E_gdi.otf') format('opentype'),
		url('../fonts/Monosten-E_gdi.svg#Monosten-E') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}
@font-face {
	font-family:'Monosten';
	src: url('../fonts/Monosten-A_gdi.eot');
	src: url('../fonts/Monosten-A_gdi.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monosten-A_gdi.woff') format('woff'),
		url('../fonts/Monosten-A_gdi.ttf') format('truetype'),
		url('../fonts/Monosten-A_gdi.otf') format('opentype'),
		url('../fonts/Monosten-A_gdi.svg#Monosten-E') format('svg');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}
@font-face {
	font-family:'Monosten';
	src: url('../fonts/Monosten-C_gdi.eot');
	src: url('../fonts/Monosten-C_gdi.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monosten-C_gdi.woff') format('woff'),
		url('../fonts/Monosten-C_gdi.ttf') format('truetype'),
		url('../fonts/Monosten-C_gdi.otf') format('opentype'),
		url('../fonts/Monosten-C_gdi.svg#Monosten-E') format('svg');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}
