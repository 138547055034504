.home {
  #home-next-events {
    margin-top: 150px;
    padding-bottom: 50px;
  }
  .container {
    p {
      text-transform: none;
    }
  }
}