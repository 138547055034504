// Base / Compass
@import "base/_reset";

// Settings
@import "settings/_colors";
@import "settings/_fonts";
@import "settings/_animation";
@import "settings/_layout";

// Mixins
@import "mixins/_rem";

// Commons
@import "commons/_buttons";
@import "commons/_elements";
@import "commons/_classes";
@import "commons/_grid";

// Layout
@import "layout/_body";
@import "layout/_header";
@import "layout/_menu";
@import "layout/_sponsors";
@import "layout/_footer";

// Pages
@import "pages/_home";
@import "pages/_standard";
@import "pages/_single-event";
@import "pages/list-events";

// Plugins


// QUERIES
$bp0: 1240px;
$bp1: 1100px;
$bp2: 900px;
$bp3: 640px;
$bp4: 400px;

@media screen and (max-width: $bp0) {
  @import "queries/_xlarge";
}

@media screen and (max-width: $bp1) {
  @import "queries/_large";
}

@media screen and (max-width: $bp2) {
  @import "queries/_medium";
}

@media screen and (max-width: $bp3) {
  @import "queries/_small";
}

@media screen and (max-width: $bp4) {
  @import "queries/_xsmall";
};