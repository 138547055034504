.standard {
  .block-text {
    h1 {
      font-size: em(36);
      font-weight: bold;
      text-align: center;
      margin: 50px 0;
    }
    h2 {
      font-size: em(24);
      font-weight: bold;
      margin: 20px auto;
    }
    h3 {
      font-size: em(18);
      font-weight: bold;
      margin: 10px auto;
    }
    p {
      font-size: em(16);
      margin: 10px auto;
      text-transform: none;
    }
  }
}