.button {
  padding: 12px 24px;
  font-size: em(14);
  border-radius: 4px;
  border: 0;
  display: inline-block;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  font-weight: 800;
  margin: 20px 0;
  transition: all .2s ease;
  background: $color2;
  color: $color1;
  &:hover {
    color: $color1;
  }
  &.button-full {
    display: block;
  }
  &.button-white {
    background: $color1;
    color: $color0;
    &:hover {
      color: $color0;
    }
  }
  &:hover {
    padding-left: 30px;
    padding-right: 30px;
  }
}