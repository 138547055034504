.article.event {
  background: $color1;
  margin: 100px auto 50px;
  border-radius: 10px;
  transition: all .3s ease;
  box-shadow: 0 4px 10px rgba(0,0,0,.5);
  overflow: hidden;
  .thumb {
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    line-height: 0;
    .youtube-block {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 18px;
      max-width: 8%;
      width: 100%;
      padding: 1%;
      background-color: transparentize(#000, .2);
      opacity: 0.8;
      border-radius: 8px;
      text-align: center;
    }
    img {
      min-width: 100%;
    }
    h2 {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 10px;
      font-size: em(24);
      font-weight: 800;
      background: rgba(0,0,0, .5);
      color: $color1;
      text-align: center;
      line-height: 1;
      span {
        display: block;
        font-size: .7em;
        margin-top: 5px;
        &.author-by {
          margin-top: 10px;
          font-weight: 400;
        }
      }
    }
  }
  .event-description {
    padding: 20px;
    text-transform: none;
    h4 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        & + li {
          margin-top: 5px;
        }
        span {
          font-weight: 800;
        }
      }
    }
  }
  .event-subtitle {
    font-weight: 800;
    padding: 30px 0 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    &:first-child {
      padding-top: 10px;
    }
  }
  .event-speaker {
    & > div {
      margin-top: 5px;
    }
    span {
      font-weight: 800;
    }
  }
}
#form-reservation {
  h3 {
    font-size: em(24);
    margin: 20px 0;
    font-weight: bold;
  }
  .form-custom {
    margin-top: 30px;
  }
}

.not-publish {
  color: red;
}
.event.not-publish {
  text-align: center;
  margin-top: 100px;
  margin-bottom: -50px;
  font-size: 24px;
}