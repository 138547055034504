@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

@font-face {
  font-family: "Monosten";
  src: url("../fonts/Monosten-E_gdi.eot");
  src: url("../fonts/Monosten-E_gdi.eot?#iefix") format("embedded-opentype"), url("../fonts/Monosten-E_gdi.woff") format("woff"), url("../fonts/Monosten-E_gdi.ttf") format("truetype"), url("../fonts/Monosten-E_gdi.otf") format("opentype"), url("../fonts/Monosten-E_gdi.svg#Monosten-E") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}
@font-face {
  font-family: "Monosten";
  src: url("../fonts/Monosten-A_gdi.eot");
  src: url("../fonts/Monosten-A_gdi.eot?#iefix") format("embedded-opentype"), url("../fonts/Monosten-A_gdi.woff") format("woff"), url("../fonts/Monosten-A_gdi.ttf") format("truetype"), url("../fonts/Monosten-A_gdi.otf") format("opentype"), url("../fonts/Monosten-A_gdi.svg#Monosten-E") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}
@font-face {
  font-family: "Monosten";
  src: url("../fonts/Monosten-C_gdi.eot");
  src: url("../fonts/Monosten-C_gdi.eot?#iefix") format("embedded-opentype"), url("../fonts/Monosten-C_gdi.woff") format("woff"), url("../fonts/Monosten-C_gdi.ttf") format("truetype"), url("../fonts/Monosten-C_gdi.otf") format("opentype"), url("../fonts/Monosten-C_gdi.svg#Monosten-E") format("svg");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}
@-webkit-keyframes no-anim {}
@-moz-keyframes no-anim {}
@-ms-keyframes no-anim {}
@keyframes no-anim {}
@-webkit-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@-moz-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@-ms-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
/* PX TO REM Mixin */
.button {
  padding: 12px 24px;
  font-size: 0.875rem;
  border-radius: 4px;
  border: 0;
  display: inline-block;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 800;
  margin: 20px 0;
  transition: all 0.2s ease;
  background: #7bc34e;
  color: #fff;
}
.button:hover {
  color: #fff;
}
.button.button-full {
  display: block;
}
.button.button-white {
  background: #fff;
  color: #555;
}
.button.button-white:hover {
  color: #555;
}
.button:hover {
  padding-left: 30px;
  padding-right: 30px;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #555;
  transition: opacity 0.3s ease;
}
a:hover {
  color: #7bc34e;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-justify {
  text-align: justify;
}

.no-desktop {
  display: none;
}

.hidden {
  display: none;
}

.block-text {
  padding: 30px 0 70px;
  line-height: 1.4;
  color: #555;
}
.block-text h1 {
  font-size: 1.5rem;
  margin: 20px 0;
  font-weight: bold;
}
.block-text ol,
.block-text ul {
  list-style-type: disc;
  padding-left: 30px;
  font-size: 1rem;
  margin: 20px auto;
}
.block-text ol li a,
.block-text ul li a {
  color: #7bc34e;
  font-weight: bold;
  text-transform: none;
}
.block-text ol li + li,
.block-text ul li + li {
  margin-top: 20px;
}
.block-text ol {
  list-style-type: decimal;
}
.block-text p {
  font-size: 16px;
}
.block-text p em {
  font-style: italic;
}
.block-text p strong {
  font-weight: bold;
}
.block-text p a {
  color: #7bc34e;
  font-weight: bold;
}

.section-white {
  background: #fff;
}

.section-title {
  font-weight: 800;
  font-size: 1.5rem;
  padding: 20px 0;
}
.section-title.section-title-centered {
  text-align: center;
  opacity: 0.3;
  position: relative;
  top: -30px;
}
.section-title.section-title-centered span {
  display: block;
  float: left;
}
.section-title.section-title-centered span.center {
  position: absolute;
  top: 19px;
  left: 50%;
  float: none;
  transform: translateX(-50%);
}
.section-title.section-title-centered span.before {
  text-align: left;
}
.section-title.section-title-centered span.after {
  float: right;
  text-align: right;
}

.form-custom input[type=text],
.form-custom input[type=email],
.form-custom input[type=number],
.form-custom textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 6px;
  background: #f5f5f5;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-success {
  text-align: center;
  background: #a8f593;
  padding: 20px;
  margin-top: 50px;
  border-radius: 20px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.col {
  width: 25%;
  float: left;
}
.col.col-2 {
  width: 50%;
}
.col.col-3 {
  width: 75%;
}
.col.col-alone {
  margin: 0 auto;
  float: none;
}

.grid {
  overflow: hidden;
  padding: 20px 0 40px;
}
.grid .grid-item.event {
  box-sizing: border-box;
  padding: 20px;
}
.grid .grid-item.event:hover .event-inner {
  top: -18px;
  box-shadow: 0 22px 30px rgba(0, 0, 0, 0.3);
}
.grid .grid-item.event.event-suggest .event-inner {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.grid .grid-item.event.event-suggest .event-inner .icon {
  padding: 20px;
  font-size: 50px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 20px 30px;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.grid .grid-item.event.event-suggest .event-inner .event-name {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.grid .grid-item.event .event-inner {
  position: relative;
  top: 0;
  min-height: 380px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: 800;
  padding-bottom: 10px;
}
.grid .grid-item.event .event-inner:after {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  bottom: 1px;
  border-radius: 10px;
}
.grid .grid-item.event .date {
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.grid .grid-item.event .date span {
  color: #fff;
  display: block;
}
.grid .grid-item.event .date span.day {
  font-size: 2.25rem;
}
.grid .grid-item.event .link-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.grid .grid-item.event .thumb {
  height: 200px;
  position: relative;
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.grid .grid-item.event .event-description {
  padding: 10px;
}
.grid .grid-item.event .event-tag {
  color: #7bc34e;
  font-size: 0.875rem;
  display: inline-block;
  margin: 10px 0;
  position: relative;
  z-index: 2;
}

body {
  font-family: Monosten, monospace;
  text-transform: lowercase;
  background: #a8f593;
  color: #555;
  transition: background-color 2s ease;
  line-height: 1.4;
}

#main-header {
  padding: 15px 0;
  background: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
#main-header #main-logo {
  margin-top: 5px;
}
#main-header #main-logo img {
  max-width: 55px;
  vertical-align: bottom;
}
#main-header #main-logo .logo-name,
#main-header #main-logo .logo-url {
  vertical-align: bottom;
  font-weight: 800;
  font-size: 0.75rem;
  position: relative;
  top: -6px;
}
#main-header #main-logo .logo-name {
  color: #aaa;
}
#main-header #main-logo .logo-url {
  color: #7bc34e;
}
#main-header + section {
  margin-top: 50px;
}

#toggle-menu-mobile {
  border: 0;
  font-size: 12px;
  padding: 4px 10px 6px;
  border-radius: 5px;
  background: #555;
  color: #fff;
  font-weight: 600;
  float: right;
}

.cms-toolbar-expanded #main-header {
  top: 50px;
}

#main-menu {
  margin-top: 5px;
}
#main-menu ul li {
  display: inline-block;
  font-weight: 800;
}
#main-menu ul li.selected a, #main-menu ul li.ancestor a {
  color: #7bc34e;
}
#main-menu ul li + li {
  margin-left: 3px;
}
#main-menu ul li + li:before {
  content: "•";
  font-size: 1.125rem;
  color: #eee;
  display: inline-block;
  margin-right: 3px;
}
#main-menu ul li a {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
}
#main-menu ul li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  transition: all 0.3s ease;
  background: #7bc34e;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
}
#main-menu ul li a:hover:after {
  width: 100%;
  height: 2px;
}

.menu-opened #main-menu {
  display: block;
  clear: both;
  float: none;
  padding-top: 20px;
  text-align: center;
}

#main-sponsors {
  background-color: #fafafa;
}
#main-sponsors p {
  margin-bottom: 25px;
  padding-top: 40px;
  font-weight: 800;
}
#main-sponsors ul {
  padding-bottom: 40px;
}
#main-sponsors li {
  display: inline-block;
  max-width: 120px;
  max-height: 120px;
  vertical-align: middle;
  padding: 0 20px;
}

#main-footer {
  padding: 10px 0;
  color: #fff;
  font-size: 0.75rem;
  background: #555;
}
#main-footer a {
  font-weight: bold;
  color: #fff;
}

.home #home-next-events {
  margin-top: 150px;
  padding-bottom: 50px;
}
.home .container p {
  text-transform: none;
}

.standard .block-text h1 {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin: 50px 0;
}
.standard .block-text h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px auto;
}
.standard .block-text h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin: 10px auto;
}
.standard .block-text p {
  font-size: 1rem;
  margin: 10px auto;
  text-transform: none;
}

.article.event {
  background: #fff;
  margin: 100px auto 50px;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.article.event .thumb {
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  line-height: 0;
}
.article.event .thumb .youtube-block {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 18px;
  max-width: 8%;
  width: 100%;
  padding: 1%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  border-radius: 8px;
  text-align: center;
}
.article.event .thumb img {
  min-width: 100%;
}
.article.event .thumb h2 {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 10px;
  font-size: 1.5rem;
  font-weight: 800;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  line-height: 1;
}
.article.event .thumb h2 span {
  display: block;
  font-size: 0.7em;
  margin-top: 5px;
}
.article.event .thumb h2 span.author-by {
  margin-top: 10px;
  font-weight: 400;
}
.article.event .event-description {
  padding: 20px;
  text-transform: none;
}
.article.event .event-description h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.article.event .event-description ul {
  list-style-type: disc;
  padding-left: 20px;
}
.article.event .event-description ul li + li {
  margin-top: 5px;
}
.article.event .event-description ul li span {
  font-weight: 800;
}
.article.event .event-subtitle {
  font-weight: 800;
  padding: 30px 0 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}
.article.event .event-subtitle:first-child {
  padding-top: 10px;
}
.article.event .event-speaker > div {
  margin-top: 5px;
}
.article.event .event-speaker span {
  font-weight: 800;
}

#form-reservation h3 {
  font-size: 1.5rem;
  margin: 20px 0;
  font-weight: bold;
}
#form-reservation .form-custom {
  margin-top: 30px;
}

.not-publish {
  color: red;
}

.event.not-publish {
  text-align: center;
  margin-top: 100px;
  margin-bottom: -50px;
  font-size: 24px;
}

#events-container {
  margin-top: 150px;
}

.past-events .event {
  filter: grayscale(1);
}

@media screen and (max-width: 900px) {
  .no-mobile {
    display: none;
  }
  .no-desktop {
    display: block;
  }
  #toggle-menu-mobile {
    display: inline-block;
  }
  #main-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .section-title.section-title-centered span.before,
  .section-title.section-title-centered span.after {
    display: none;
  }
  .col {
    width: 50%;
  }
  .col.col-2 {
    width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .grid .grid-item.col {
    width: 100%;
  }
}