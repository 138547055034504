#main-menu {
  margin-top: 5px;
  ul {
    li {
      display: inline-block;
      font-weight: 800;
      &.selected,
      &.ancestor {
        a {
          color: $color2;
        }
      }
      & + li {
        margin-left: 3px;
        &:before {
          content: "•";
          font-size: em(18);
          color: $color4;
          display: inline-block;
          margin-right: 3px;
        }
      }
      a {
        position: relative;
        display: inline-block;
        font-size: em(14);
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          transition: all .3s ease;
          background: $color2;
          top: 120%;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          &:after {
            width: 100%;
            height: 2px;
          }
        }
      }
    }
  }
}
.menu-opened {
  #main-menu {
    display: block;
    clear: both;
    float: none;
    padding-top: 20px;
    text-align: center;
  }
}