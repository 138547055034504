img,
svg {
  max-width: 100%;
  height: auto;
}
strong {
  font-weight: bold;
}
a {
  text-decoration: none;
  color: $color0;
  transition: opacity .3s ease;
  &:hover {
    color: $color2;
  }
}